<template>
	<div class="per-info-warp" v-if="hassearch">
		<div class="avatar">
			<img v-if="personInfo.avatarUrl" :src="showAvatarUrl(personInfo.avatarUrl)" @error="imgOnError" />
			<img v-else :src="defalutImg" />
		</div>
		<div class="tagwarp">
			<div class="flex-start per-tag">
				<div class="nick-name">{{personInfo.nickName}}</div>
				<div :class="personInfo.sex == '0'?'man':'woman'"></div>
				<div class="flex-start smdb">
					<div class="sm" :class="[{'active':(personInfo.isRealname == '1')}]">
						<div class="icon-sm"></div>
						<div>{{personInfo.isRealname == '1'?'已实名':'未实名'}}</div>
					</div>
					<div class="db" :class="[{'active':(personInfo.isDb == '1')}]">
						<div class="icon-db"></div>
						<div>{{personInfo.isDb == '1'?'已担保':'未担保'}}</div>
					</div>
				</div>
			</div>
			<div class="flex-start per-tag">
				<div class="tag">{{personInfo.work}}</div>
				<div class="tag" v-if="personInfo.area">
					<div class="area-name margr4">{{$util.getAreaNameByAc(personInfo.area)[0]}}</div>
					<div class="margr4">/</div>
					<div class="area-name">{{$util.getAreaNameByAc(personInfo.area)[1]}}</div>
				</div>
				<div class="tag" v-if="personInfo.isMarriage">
					{{$util.getDisPlay(personInfo.isMarriage,$util.marriageList)}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo
	} from '@/api/index';
	export default {
		data() {
			return {
				personInfo:{
					avatarUrl:'',
					nickName:'',
					work:'',
					membercoins:'',
					area:'',
					isMarriage:'',
					isRealname:'',
					isDb:'',
					attenTionNum:'',
					fansNum:'',
					publishNum:'',
					sex:''
				},
				baseUrlpath:'',
				defalutImg:require('@/assets/icon/noimg.jpg'),
				hassearch:false,
				inchina:''
			}
		},
		props: {
			personId: {
				type: String,
				required: true
			}
		},
		created() {
			console.log("personInfo===>created")
			this.inchina = localStorage.getItem('inChina');
		},
		mounted() {
			console.log("personInfo===>mounted")
			this.inchina = localStorage.getItem('inChina');
			this.getPersonInfo(this.personId)
		},
		methods:{
			imgOnError(e){
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			showAvatarUrl(avatarUrl){
				if(avatarUrl){
					if(avatarUrl.indexOf('http') >= 0){
						return avatarUrl;
					}else{
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			async getPersonInfo(personId){
				var vm = this;
				var requestUrl = "/apis/getPersonInfoByUserId"
				if(this.inchina == '1'){
					requestUrl = '/cnapis/getPersonInfoByUserId'
				}
				var params = {};
				params.queryUserId = personId;
				const initres = await queryUrlWithUInfo(requestUrl,params);
				if(initres.ec == '0'){
					vm.personInfo.avatarUrl = vm.showAvatarUrl(initres.sdu_avatarUrl);
					vm.personInfo.nickName = initres.sdu_nickName;
					vm.personInfo.work = initres.sdu_work;
					vm.personInfo.area = initres.sdu_areaId;
					vm.personInfo.isMarriage = initres.sdu_isMarriage;
					vm.personInfo.isRealname = initres.sdu_isRealname;
					vm.personInfo.isDb = initres.sdu_isDb;
					vm.personInfo.attenTionNum = initres.attenTionNum;
					vm.personInfo.fansNum = initres.fansNum;
					vm.personInfo.publishNum = initres.publishNum;
					vm.personInfo.sex = initres.sdu_sex;
				}
				this.hassearch = true
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.per-info-warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;

		.tagwarp {
			height: 70px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;
			margin-left: 10px;
		}

		.per-tag {
			font-size: 18px;
			color: #181818;

			.nick-name {
				font-weight: 600;
			}

			.man {
				background: url(../assets/icon/man.png) center no-repeat;
				background-size: 100%;
				height: 16px;
				width: 16px;
				margin-left: 8px;
			}

			.woman {
				background: url(../assets/icon/woman.png) center no-repeat;
				background-size: 100%;
				height: 16px;
				width: 16px;
				margin-left: 8px;
			}

			.tag {
				margin-right: 14px;
				display: flex;

				.area-name {
					font-size: 18px;
				}
			}

			.smdb {
				font-size: 12px;

				.sm {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					background-color: #8C939D;
					color: #FFFFFF;
					padding: 4px 6px;
					border-radius: 6px;
					margin-left: 10px;

					.icon-sm {
						background: url(../assets/icon/sm.png) center no-repeat;
						background-size: 100%;
						height: 14px;
						width: 14px;
						margin-right: 4px;
					}
				}

				.db {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					background-color: #8C939D;
					color: #FFFFFF;
					padding: 4px 6px;
					border-radius: 6px;
					margin-left: 10px;

					.icon-db {
						background: url(../assets/icon/db.png) center no-repeat;
						background-size: 100%;
						height: 14px;
						width: 14px;
						margin-right: 4px;
					}
				}
			}

			.active {
				background-color: #c88854 !important;
			}
		}

		.avatar {
			width: 70px;
			height: 70px;
			border-radius: 22px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
</style>
