<template>
	<div style="width: 100%;">
		<div class="item_warp" v-if="!isDetail">
			<div class="ctx-item-t">
				<div class="ctx-item-head">是否显示联系方式<text class="red">*</text></div>
			</div>
			<div class="ts-ctx">
				<el-switch
				  v-model="isShowConcactChild"
				  @change="handleChange"
				  active-color="#13ce66"
				  inactive-color="#ff4949">
				</el-switch>
			</div>
		</div>
		<div class="line" v-if="!isDetail"></div>
		<div v-if="isShowConcactChild">
			<div>
				<div class="contact_head">
					<div class="contact_head_tit">联系方式</div>
					<div class="flex-start modify_warp" @click="goModifyConcact()" v-if="!isDetail && (wxShow || emailShow || whatsAppShow || phoneShow)">
						<div class="txt">修改</div>
						<div class="modify_icon"></div>
					</div>
				</div>
				<div class="contact_body">
					<div v-if="phoneShow">
						<div class="flex-start">
							<div>手机号：</div>
							<div>{{sduc_phoneNum}}</div>
						</div>
					</div>
					<div v-if="whatsAppShow">
						<div class="flex-start">
							<div>WhatsApp：</div>
							<div v-if="!isDetail">{{$util.formateWhatsApp(sduc_whatsAppAc,sduc_whatsAppNum)}}</div>
							<div class="whatsapp_warp" v-else>
								<div class="icon_whatsapp"></div>
								<div class="icon_msg"><a :href="whatsAppLink()" target="_blank">+{{$util.formateWhatsApp(sduc_whatsAppAc,sduc_whatsAppNum)}}</a></div>
							</div>
						</div>
					</div>
					<div v-if="emailShow">
						<div class="flex-start">
							<div>邮件(E-MAIL)：</div>
							<div>{{sduc_emailNum}}</div>
						</div>
					</div>
					<div v-if="wxShow">
						<div class="flex-start">
							<div>微信号：</div>
							<div>{{sduc_wxCode}}</div>
						</div>
						<div class="flex-start-start">
							<div>微信二维码：</div>
							<div class="image_warp">
								<el-image :src="baseUrlpath + sduc_wxQrcode" mode="aspectFill" :preview-src-list="wxQrcodeSrcList"></el-image>
							</div>
						</div>
					</div>
					<div v-if="!wxShow && !emailShow && !whatsAppShow && !phoneShow">
						<div class="empty-msg" style="" v-if="!isDetail">
							<label>无可展示的信息，</label>
							<label class="link_msg" @click="goModifyConcact()" >去编辑</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo,
		postWithUInfo
	} from '@/api/index';
	export default {
		data(){
			return {
				isShowConcactChild:false,
				sduc_phoneNum:'',
				sduc_wxCode:'',
				sduc_wxQrcode:'',
				qrcodeImage:'',
				wxShow:false,
				emailShow:false,
				phoneShow:false,
				whatsAppShow:false,
				sduc_whatsAppNum:'',
				sduc_whatsAppAc:'',
				sduc_emailNum:'',
				userId:'',
				wxQrcodeSrcList:[],
				inchina:''
			}
		},
		props:{
			queryUid:{
				type:String,
				required:true
			},
			baseUrlpath:{
				type:String,
				required:true
			},
			isShowConcact:{
				type:Boolean,
				required:true
			},
			isDetail:{
				type:Boolean,
				required:true
			}
		},
		computed : {
			
		},
		onLoad() {
			
		},
		onShow() {
			this.inchina = localStorage.getItem('inChina');
			this.isShowConcactChild = this.isShowConcact
			this.userId = this.queryUid
			this.queryConcact()
		},
		mounted() {
			console.log('mounted')
			this.inchina = localStorage.getItem('inChina');
			this.isShowConcactChild = this.isShowConcact
			this.userId = this.queryUid
			this.queryConcact()
		},
		watch:{
		    isShowConcact(n,o){ //n为新值,o为旧值;
		      this.isShowConcactChild = n;
		    },
			queryUid(n,o){
				console.log(n)
				this.userId = n
				this.queryConcact()
			}
		},
		methods: {
			whatsAppLink(){
				return 'https://wa.me/' + this.$util.formateWhatsApp(this.sduc_whatsAppAc,this.sduc_whatsAppNum)
			},
			handleChange(e){
				console.log(e)
				this.isShowConcactChild = e
				this.$emit("changeConcactShow",e);
			},
			async queryConcact(){
				var requestUrl = "/apis/queryUserContact"
				if(this.inchina == '1'){
					requestUrl = '/cnapis/queryUserContact'
				}
				const initres = await queryUrl(requestUrl, {queryUid:this.userId})
				if(initres.ec == '0'){
					this.wxShow = initres.sduc_wxShow == '1' ? true : false;
					this.phoneShow = initres.sduc_phoneShow == '1' ? true : false;
					this.whatsAppShow = initres.sduc_whatsAppShow == '1' ? true : false;
					this.emailShow = initres.sduc_emailShow == '1' ? true : false;
					this.sduc_phoneNum = initres.sduc_phoneNum;
					this.sduc_emailNum = initres.sduc_emailNum;
					this.sduc_wxCode = initres.sduc_wxCode;
					this.sduc_wxQrcode = initres.sduc_wxQrcode;
					this.sduc_whatsAppNum = initres.sduc_whatsAppNum;
					this.sduc_whatsAppAc = initres.sduc_whatsAppAc;
					this.wxQrcodeSrcList = [];
					this.wxQrcodeSrcList.push(this.baseUrlpath + this.sduc_wxQrcode)
				}
			},
			goModifyConcact(){
				this.$router.push({
				  name: 'personCenter',
				  query: {activeType:'contact'}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.empty-msg {
		padding:30px 0;
		font-size: 14px;
		.link_msg {
			color: #DD6161;
			cursor: pointer;
			text-decoration:underline
		}
	}
	
	.whatsapp_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		//margin-left: 20px;
		cursor: pointer;
		.icon_whatsapp {
			background: url(../assets/icon/whatsapp.png) no-repeat center;
			background-size: 100%;
			width: 30px;
			height: 30px;
		}
		.icon_msg {
			a {
				margin-left: 6px;
				color: #64b161;
				letter-spacing: 2px;
				font-weight: 700;
			}
			
		}
	}
	.line {
	    border-bottom: 1px solid #b1abab2e;
		margin: 16px 0;
	}
	.contact_body {
		font-size: 18px;
		padding: 0px 10px;
		line-height: 42px;
		margin: 16px 0;
		.image_warp {
			height: 100px;
			width: 100px;
			position: relative;
			top: 6px;
			border-radius: 4px;
			overflow: hidden;
			.el-image {
				height: 100%;
				width: 100%;
			}
		}
	}
	.contact_head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 15px;
		position: relative;
		padding: 0 10px;
		margin-top: 16px;
		.contact_head_tit {
			font-weight: 700;
			font-size: 18px;
		}
		.contact_head_tit:after {
			content: '';
			position: absolute;
			background-color: #c88854;
			height: 20px;
			width: 4px;
			top: 50%;
			left: 0;
			border-radius: 8px;
			transform: translate(0, -50%);
		}
		.modify_warp {
			color: #c88854;
			cursor: pointer;
			.txt {
				line-height: 24px;
			}
		}
		.modify_icon {
			background: url(../assets/icon/modify.png) center no-repeat;
			background-size: 100%;
			width: 24px;
			height: 24px;
			margin-left: 4px;
		}
	}
	.item_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.ctx-item-t {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.ctx-item-head {
				font-size: 18px;
				font-weight: 700;
			}
		}
		.ts-ctx {
			flex: 1;
			text-align: right;
		}
	}
</style>
