import { render, staticRenderFns } from "./personInfo.vue?vue&type=template&id=98c247ea&scoped=true&"
import script from "./personInfo.vue?vue&type=script&lang=js&"
export * from "./personInfo.vue?vue&type=script&lang=js&"
import style0 from "./personInfo.vue?vue&type=style&index=0&id=98c247ea&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98c247ea",
  null
  
)

export default component.exports