<template>
	<div class="container" ref="reqDetail">
		<el-page-header @back="goBack" content="详情页面">
		</el-page-header>
		<div class="conts" v-loading="searchLoading">
			<div class="pad_cons" v-if="reqInfo">
				<div class="">
					<div class="cons_left">
						<div class="carousel-warp">
							<template v-if="reqInfo.attachlist.length > 0">
								<template v-if="$util.isVideo(reqInfo.attachlist[0].sdra_filePath)">
									<video :src="reqInfo.attachlist[0].sdra_filePath" controls="controls" loop="loop"
										:id="reqInfo.attachlist[0].sdra_fileid"
										@play="play(reqInfo.attachlist[0].sdra_fileid)"></video>
								</template>
								<template v-else>
									<el-carousel :interval="5000" arrow="always" height="520px">
										<el-carousel-item v-for="imageInfo in reqInfo.attachlist"
											:key="imageInfo.sdra_fileid">
											<div class="imagewarp" @click="handlePreview(baseUrlpath+imageInfo.sdra_filePath)">
												<img :src="baseUrlpath+imageInfo.sdra_filePath" @error="imgOnError" />
											</div>
										</el-carousel-item>
									</el-carousel>
									<!-- <detailSwiper :attachlist="reqInfo.attachlist" :baseUrlpath="baseUrlpath"></detailSwiper> -->
								</template>
							</template>
							<template v-else>
								<img src="ss" @error="imgOnError" />
							</template>
						</div>
						<div class="flex-start-start">
							

						
							<div class="tb-wrap">
								<div class="ri-body">
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '1'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_carry_model,$util.carryModelList)}}
												</div>
											</div>
											<template v-if="$util.isNotEmpty(reqInfo.sdr_carry_initPrice)">
												<div class="flex-center-baseline nail-price font-bold">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_carry_initPrice}}</div>
												</div>
											</template>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '9'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_car_transModel,$util.carTransModel)}}
												</div>
											</div>
											<div class="flex-start red font-bold padt10">
												<div v-if="reqInfo.sdr_car_offerType == '0'" style="font-size: 32rpx;">可以协商
												</div>
												<div class="flex-center-baseline nail-price"
													v-if="reqInfo.sdr_car_offerType == '1'">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_car_price}}</div>
													<div class="amt-unit"
														v-if="reqInfo.sdr_car_transModel == '2' || reqInfo.sdr_car_transModel == '3'">
														<div class="unit-split">/</div>
														<div>
															{{$util.getDisPlay(reqInfo.sdr_car_transUnit,$util.unitOfTransList)}}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '7'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">{{reqInfo.sdr_discount_proName}}
												</div>
											</div>
											<div class="flex-start red font-bold padt10">
												<div class="flex-center-baseline nail-price">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_discount_proPrice}}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '10'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_travel_tag,$util.travelLebels)}}
												</div>
											</div>
											<div class="flex-start red font-bold padt10" v-if="$util.isNotEmpty(reqInfo.sdr_travel_price)">
												<div class="flex-center-baseline nail-price">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_travel_price}}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '12'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_delicacy_tag,$util.delicacyLebels)}}
												</div>
											</div>
											<div class="flex-start red font-bold padt10"
												v-if="reqInfo.sdr_delicacy_price.length > 0">
												<div class="flex-center-baseline nail-price">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_delicacy_price}}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '13'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{reqInfo.sdr_cnyGood_proName}}
												</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_cnyGood_locally,$util.locallyHasPro)}}
												</div>
											</div>
											<div class="flex-start red font-bold padt10">
												<div class="flex-center-baseline nail-price">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_cnyGood_proPrice}}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '14'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_roast_tagIndex,$util.businessOppoLebels)}}
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '15'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_contact_tagIndex,$util.localContactsLabels)}}
												</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '16'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
											</div>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '11'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_fang_transModel,$util.fangTransModel)}}
												</div>
											</div>
											<div class="flex-start" v-if="$util.isNotEmpty(reqInfo.sdr_fang_price)">
												<div class="flex-center-baseline nail-price">
													<div class="currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_fang_price}}</div>
													<div class="amt-unit"
														v-if="['2','2-1','2-2','3'].includes(reqInfo.sdr_fang_transModel)">
														<div class="unit-split">/</div>
														<div>
															{{$util.getDisPlay(reqInfo.sdr_fang_transUnit,$util.unitOfTransList)}}
														</div>
													</div>
												</div>
											</div>
										</div>
							
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '3'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_invite_model,$util.inviteModelList)}}
												</div>
												<template v-if="reqInfo.sdr_invite_model == '0' || reqInfo.sdr_invite_model == '1'">
													<div class="point mrgr">|</div>
													<div v-if="reqInfo.sdr_invite_model == '0'">{{reqInfo.sdr_invite_jobName}}</div>
													<div v-else>
														{{$util.getDisPlay(reqInfo.sdr_invite_inviteTypeIndex,$util.inviteLabels)}}
													</div>
												</template>
											</div>
											<template v-if="reqInfo.sdr_invite_model.startsWith('nkcor')">
												
											</template>
											<template v-else>
												<div class="flex-start nail-price">
													<div>薪资：</div>
													<div class="amt" v-if="reqInfo.sdr_salary_offerType == '0'">面议</div>
													<div class="margr10 flex-center-baseline"
														v-if="reqInfo.sdr_salary_offerType == '1'">
														<div class="currency_type mrgr6">{{reqInfo.currency_symbol}}</div>
														<div class="amt margr10">{{reqInfo.salary_startRange}}</div>
														<div class="margr10">-</div>
														<div class="amt">{{reqInfo.salary_endRange}}</div>
													</div>
												</div>
											</template>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '2'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">
													{{$util.getDisPlay(reqInfo.sdr_sfcar_model,$util.carModelList)}}
												</div>
											</div>
											<template v-if="reqInfo.sdr_sfcar_model.startsWith('nkcor')">
												
											</template>
											<template v-else>
												<div class="flex-start nail-price font-bold">
													<div class="amt" v-if="reqInfo.sdr_sfcar_offerType == '0'">可以协商</div>
													<div class="flex-center-baseline" v-if="reqInfo.sdr_sfcar_offerType == '1'">
														<div class="currency_type">{{reqInfo.currency_symbol}}</div>
														<div class="amt">
															{{reqInfo.sdr_sfcar_initPrice}}
														</div>
													</div>
												</div>
											</template>
										</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '5'">
										<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
										<div class="point mrgr">|</div>
										<div class="mrgr">{{reqInfo.sdr_activity_name}}</div>
									</div>
									<div class="rib-head" v-if="reqInfo.sdr_reqType == '4'">
										<div class="flex-between hundred">
											<div class="flex-start">
												<div class="mrgr">{{$util.getDisPlay(reqInfo.sdr_reqType,$util.reqList)}}</div>
												<div class="point mrgr">|</div>
												<div class="mrgr">{{reqInfo.sdr_proName}}</div>
											</div>
											<div class="flex-start nail-price font-bold">
												<div class="np flex-center-baseline margr10">
													<div class="mrgr6 currency_type">{{reqInfo.currency_symbol}}</div>
													<div class="amt">{{reqInfo.sdr_proNewPrice}}</div>
												</div>
												<div class="op flex-start">
													<div>新品</div>
													<div class="flex-center-baseline">
														<div style="font-size: 16rpx;">{{reqInfo.currency_symbol}}</div>
														<div>{{reqInfo.sdr_proOldPrice}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="con_area">
										<div v-if="reqInfo.sdr_reqType == '4'">
											<div class="flex-start margt10">
												<div class="lb">看货地址：</div>
												<div>{{reqInfo.sdr_addrDetail}}</div>
											</div>
										</div>
										<div v-if="reqInfo.sdr_reqType == '11'">
											<template v-if="reqInfo.sdr_fang_transModel.startsWith('nk')">
												<div class="item_mb margt10 req-contmsg" v-if="reqInfo.sdr_fang_transModel.startsWith('nkcor')">{{reqInfo.sdr_storeCorName}}</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_fang_transModel.startsWith('nkcor')">
													<div class="lb">地址：</div>
													<div>{{reqInfo.sdr_storeCorAddr}}</div>
												</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_fang_transModel.startsWith('nkcor')">
													<div class="req-contmsg" v-html="$util.formateContentRevise(reqInfo.sdr_storeCorDesc)"></div>
												</div>
											</template>
											<template v-else>
												<div class="rib-nail">
													<div class="flex-start normal-color margt10">
														<el-statistic title="面积">
															<template slot="formatter">{{reqInfo.sdr_fang_measure}}平</template>
														</el-statistic>
														<template v-if="['0','1','2','3'].includes(reqInfo.sdr_fang_transModel)">
															<el-statistic title="户型">
																<template
																	slot="formatter">{{$util.getHoseTypeVal(reqInfo.sdr_fang_houseType,$util.houseTypes)}}</template>
															</el-statistic>
														</template>
													</div>
													<div class="flex-start margt10" v-if="['0','1','2','3'].includes(reqInfo.sdr_fang_transModel)">
														<div class="lb">小区名称：</div>
														<div>{{reqInfo.sdr_fang_communityName}}</div>
													</div>
													<div class="flex-start margt10">
														<div class="lb">看房地址：</div>
														<div>{{reqInfo.sdr_addrDetail}}</div>
													</div>
												</div>
											</template>
										</div>
										<div v-if="reqInfo.sdr_reqType == '12'">
											<div class="rib-nail">
												<div class="flex-start margt10">
													<div class="lb">餐厅名称：</div>
													<div>{{reqInfo.sdr_restaurant_name}}</div>
												</div>
												<div class="flex-start margt10">
													<div class="lb">餐厅地址：</div>
													<div>{{reqInfo.sdr_delicacy_place}}</div>
												</div>
											</div>
										</div>
										<div v-if="reqInfo.sdr_reqType == '9'">
											<div class="rib-nail">
												<template v-if="reqInfo.sdr_car_transModel.startsWith('nk')">
													<div class="item_mb margt10 req-contmsg" v-if="reqInfo.sdr_car_transModel.startsWith('nkcor')">{{reqInfo.sdr_storeCorName}}</div>
													<div class="flex-start margt10" v-if="reqInfo.sdr_car_transModel.startsWith('nkcor')">
														<div class="lb">地址：</div>
														<div>{{reqInfo.sdr_storeCorAddr}}</div>
													</div>
													<div class="flex-start margt10" v-if="reqInfo.sdr_car_transModel.startsWith('nkcor')">
														<div class="req-contmsg" v-html="$util.formateContentRevise(reqInfo.sdr_storeCorDesc)"></div>
													</div>
												</template>
												<template v-else>
													<div class="flex-start normal-color margt10"
														v-if="reqInfo.sdr_car_transModel == '1' || reqInfo.sdr_car_transModel == '2'">
														<el-statistic title="机车品牌">
															<template slot="formatter">{{reqInfo.sdr_car_brand}}</template>
														</el-statistic>
														<el-statistic title="年份">
															<template slot="formatter">{{reqInfo.sdr_car_buyYear}} 年</template>
														</el-statistic>
														<el-statistic title="表显里程">
															<template slot="formatter">{{reqInfo.sdr_car_kilometre}} 公里</template>
														</el-statistic>
													</div>
													<div class="flex-start margt10"
														v-if="reqInfo.sdr_car_transModel == '1' || reqInfo.sdr_car_transModel == '2'">
														<div class="lb">看车地址：</div>
														<div>{{reqInfo.sdr_car_lookaddr}}</div>
													</div>
												</template>
											</div>
										</div>
										<div v-if="reqInfo.sdr_reqType == '1'">
											<div class="">
												<template v-if="reqInfo.sdr_carry_model.startsWith('nk')">
													<div class="item_mb margt10 req-contmsg" v-if="reqInfo.sdr_carry_model.startsWith('nkcor')">{{reqInfo.sdr_storeCorName}}</div>
													<div class="flex-start margt10" v-if="reqInfo.sdr_carry_model.startsWith('nkcor')">
														<div class="lb">地址：</div>
														<div>{{reqInfo.sdr_storeCorAddr}}</div>
													</div>
													<div class="flex-start margt10" v-if="reqInfo.sdr_carry_model.startsWith('nkcor')">
														<div class="req-contmsg" v-html="$util.formateContentRevise(reqInfo.sdr_storeCorDesc)"></div>
													</div>
												</template>
												<template v-else>
													<div class="flex-start margt10" v-if="reqInfo.sdr_carry_model == '1' || reqInfo.sdr_carry_model == '0'">
														<div class="carry_from_place">{{reqInfo.sdr_carry_fromWhere}}</div>
														<div class="icon-direct"></div>
														<div class="carry_to_place">{{reqInfo.sdr_carry_toWhere}}</div>
													</div>
													<div class="flex-start normal-color margt10"
														v-if="reqInfo.sdr_carry_model == '1'">
														<div class="flex-start margr20">
															<div class="lb">航班号：</div>
															<div>{{reqInfo.sdr_flight_number}}</div>
														</div>
														<div class="flex-start margr20">
															<div class="lb">航班时间：</div>
															<div>{{$util.formateSelfDate(reqInfo.sdr_carry_limittime)}}</div>
														</div>
														<div class="flex-start">
															<div class="lb">可带：</div>
															<div class="flex-start margr10">
																<div>{{reqInfo.sdr_carry_weight}}</div>
																<div>kg</div>
															</div>
														</div>
													</div>
													<div class="flex-start normal-color margt10"
														v-if="reqInfo.sdr_carry_model == '0' || reqInfo.sdr_carry_model == '2'">
														<div class="flex-start margr20">
															<div class="lb">货物名称：</div>
															<div>{{reqInfo.sdr_carry_proName}}</div>
														</div>
														<div class="flex-start margr20">
															<div class="lb">货物重量：</div>
															<div class="flex-start margr10">
																<div>{{reqInfo.sdr_carry_weight}}</div>
																<div>kg</div>
															</div>
														</div>
													</div>
													<div class="flex-start margt10">
														<div class="lb">取货地址：</div>
														<div>{{reqInfo.sdr_addrDetail}}</div>
													</div>
												</template>
											</div>
										</div>
										<div v-if="reqInfo.sdr_reqType == '2'">
											<template v-if="reqInfo.sdr_sfcar_model.startsWith('nk')">
												<div class="item_mb margt10 req-contmsg" v-if="reqInfo.sdr_sfcar_model.startsWith('nkcor')">{{reqInfo.sdr_storeCorName}}</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_sfcar_model.startsWith('nkcor')">
													<div class="lb">地址：</div>
													<div>{{reqInfo.sdr_storeCorAddr}}</div>
												</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_sfcar_model.startsWith('nkcor')">
													<div class="req-contmsg" v-html="$util.formateContentRevise(reqInfo.sdr_storeCorDesc)"></div>
												</div>
											</template>
											<template v-else>
												<div class="flex-column-start">
													<div class="flex-column-start hundred">
														<div class="flex-center">
															<div class="carry_from_place">{{reqInfo.sdr_sfcar_fromWhere}}</div>
															<div class="icon-direct"></div>
															<div class="carry_to_place">{{reqInfo.sdr_sfcar_toWhere}}</div>
														</div>
														<div class="flex-between margt10 hundred">
															<div class="flex-start margr10">
																<div class="lb">出发时间：</div>
																<div>
																	{{reqInfo.sdr_sfcar_gotime}}
																</div>
															</div>
														</div>
													</div>
												</div>
											</template>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '13'">
											<div class="flex-start margt10">
												<div v-html="$util.formateContentRevise(reqInfo.sdr_cnyGood_proDesc)"></div>
											</div>
											<div class="flex-start margt10">
												<div>公司名称：</div>
												<div class="flexone">{{reqInfo.sdr_cnyGood_corName}}</div>
											</div>
											<div class="flex-start margt10">
												<div>产地：</div>
												<div class="flexone">{{reqInfo.sdr_cnyGood_proLocation}}</div>
											</div>
											<div class="flex-start margt10">
												<div>看货地址：</div>
												<div class="flexone">{{reqInfo.sdr_addrDetail}}</div>
											</div>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '14'">
											<div class="flex-start margt10">
												<div>发生日期：</div>
												<div class="flexone">{{reqInfo.sdr_roast_date}}</div>
											</div>
											<div class="flex-start margt10">
												<div>地点：</div>
												<div class="flexone">{{reqInfo.sdr_roast_addr}}</div>
											</div>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '15'">
											<div>{{reqInfo.sdr_contact_name}}</div>
											<div v-if="reqInfo.sdr_contact_tagIndex == '26'">TEL:{{reqInfo.sdr_contact_PhoneNum}}</div>
											<div v-else class="flex-start margt10">
												<div>地点：</div>
												<div class="flexone">{{reqInfo.sdr_contact_detailAddr}}</div>
											</div>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '3'">
											<template v-if="reqInfo.sdr_invite_model.startsWith('nk')">
												<div class="item_mb margt10 req-contmsg" v-if="reqInfo.sdr_invite_model.startsWith('nkcor')">{{reqInfo.sdr_storeCorName}}</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_invite_model.startsWith('nkcor')">
													<div class="lb">地址：</div>
													<div>{{reqInfo.sdr_storeCorAddr}}</div>
												</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_invite_model.startsWith('nkcor')">
													<div class="req-contmsg" v-html="$util.formateContentRevise(reqInfo.sdr_storeCorDesc)"></div>
												</div>
											</template>
											<template v-else>
												<template v-if="reqInfo.sdr_invite_model == '0'">
													<div class="flex-start margt10">
														<div class="flex-start">
															<div class="lb">公司名称：</div>
															<div>{{reqInfo.sdr_invite_corName}}</div>
														</div>
													</div>
													<div class="flex-start margt10">
														<div class="lb">公司地址：</div>
														<div>{{reqInfo.sdr_invite_corAddr}}</div>
													</div>
													<div class="flex-start margt10">
														<div class="lb">招聘职位：</div>
														<div>{{reqInfo.sdr_invite_jobName}}</div>
													</div>
													<div class="flex-start-start margt10">
														<div class="lb">工作内容：</div>
														<div class="flexone" v-html="$util.formateContentRevise(reqInfo.sdr_invite_workNail)"></div>
													</div>
												</template>
												<template v-if="reqInfo.sdr_invite_model == '1'">
													<div class="flex-start margt10">
														<div>岗位：</div>
														<div>
															{{$util.getDisPlay(reqInfo.sdr_invite_inviteTypeIndex,$util.inviteLabels)}}
														</div>
													</div>
													<div class="flex-start margt10">
														<div>技能经验：</div>
														<div class="flexone" v-html="$util.formateContentRevise(reqInfo.sdr_invite_workNail)"></div>
													</div>
												</template>
											</template>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '5'">
											<div class="flex-start margt10">
												<div class="lb">主办方：</div>
												<div>{{reqInfo.sdr_activity_host}}</div>
											</div>
											<div class="flex-start margt10">
												<div class="lb">活动地点：</div>
												<div>{{reqInfo.sdr_activity_place}}</div>
											</div>
											<div class="flex-start margt10">
												<div class="lb">活动时间：</div>
												<div class="flex-start">
													<div class="margr10">{{reqInfo.sdr_activity_starttime}}</div>
													<div class="margr10">~</div>
													<div>{{reqInfo.sdr_activity_endtime}}</div>
												</div>
							
											</div>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '4'">
							
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '7'">
											<div class="flex-start margt10">
												<div>店面名称：</div>
												<div>{{reqInfo.sdr_discount_shopName}}</div>
											</div>
											<div class="flex-start margt10">
												<div>店面地点：</div>
												<div>{{reqInfo.sdr_discount_shopAddr}}</div>
											</div>
											<div class="flex-start margt10">
												<div>折扣日期：</div>
												<div class="flex-start-baseline">
													<div class="margr10">{{reqInfo.sdr_discount_startDate}}</div>
													<div class="margr10">至</div>
													<div class="margr10">{{reqInfo.sdr_discount_endDate}}</div>
												</div>
											</div>
											<div class="flex-start margt10">
												<div v-html="$util.formateContentRevise(reqInfo.sdr_discount_proDesc)"></div>
											</div>
										</div>
										<div class="flex-column-start" v-if="reqInfo.sdr_reqType == '10'">
											<template v-if="reqInfo.sdr_travel_tag.startsWith('nk')">
												<div class="item_mb margt10 req-contmsg" v-if="reqInfo.sdr_travel_tag.startsWith('nkcor')">{{reqInfo.sdr_storeCorName}}</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_travel_tag.startsWith('nkcor')">
													<div class="lb">地址：</div>
													<div>{{reqInfo.sdr_storeCorAddr}}</div>
												</div>
												<div class="flex-start margt10" v-if="reqInfo.sdr_travel_tag.startsWith('nkcor')">
													<div class="req-contmsg" v-html="$util.formateContentRevise(reqInfo.sdr_storeCorDesc)"></div>
												</div>
											</template>
											<template v-else>
												<div v-if="reqInfo.sdr_travel_tag == '0'">
													<div class="flex-start margt10">
														<div>出发地：</div>
														<div>{{reqInfo.sdr_travel_fromWhere}}</div>
													</div>
													<div class="flex-start margt10">
														<div>目的地：</div>
														<div>{{reqInfo.sdr_travel_toWhere}}</div>
													</div>
													<div class="flex-start margt10">
														<div>航班时间：</div>
														<div class="flex-start">
															<div class="mrgr10">{{reqInfo.sdr_travel_limitdate}}</div>
														</div>
													</div>
													<div class="flex-start margt10">
														<div>航班号：</div>
														<div class="flex-start">
															<div class="mrgr10">{{reqInfo.sdr_flight_number}}</div>
														</div>
													</div>
												</div>
												<div v-if="reqInfo.sdr_travel_tag == '1' || reqInfo.sdr_travel_tag == '4'">
													<div class="flex-start-start margt10">
														<div>{{reqInfo.sdr_travel_hotelName}}</div>
													</div>
													<div class="flex-start margt10">
														<div>使用日期：</div>
														<div>{{reqInfo.sdr_travel_serviceDate}}</div>
													</div>
													<div class="flex-start margt10">
														<div>到期日期：</div>
														<div>{{reqInfo.sdr_travel_expiryDate}}</div>
													</div>
												</div>
											</template>
										</div>
										<template v-if="(reqInfo.sdr_reqType == '1' && reqInfo.sdr_carry_model.startsWith('nkcor')) ||
											(reqInfo.sdr_reqType == '2' && reqInfo.sdr_sfcar_model.startsWith('nkcor')) || 
											(reqInfo.sdr_reqType == '3' && reqInfo.sdr_invite_model.startsWith('nkcor')) || 
											(reqInfo.sdr_reqType == '9' && reqInfo.sdr_car_transModel.startsWith('nkcor')) || 
											(reqInfo.sdr_reqType == '10' && reqInfo.sdr_travel_tag.startsWith('nkcor')) || 
											(reqInfo.sdr_reqType == '11' && reqInfo.sdr_fang_transModel.startsWith('nkcor'))">
											
										</template>
										<template v-else>
											<div class="flex-start margt10">
												<div>面向地区：</div>
												<div>
													<label>{{$util.getAreaNameByAc(reqInfo.sdr_dqmodel)[0]}}</label>
													<template v-if="reqInfo.sdr_dqmodel.indexOf('9999') < 0">
														<label>·</label>
														<label>{{$util.getAreaNameByAc(reqInfo.sdr_dqmodel)[1]}}</label>
													</template>
												</div>
											</div>
										</template>
										<div class="rib-nail" v-if="reqInfo.sdr_contents"
											v-html="$util.formateContentRevise(reqInfo.sdr_contents)"></div>
									</div>
									
								</div>
							
								<div class="ri_foot">
									<div class="">
										<template v-if="reqInfo.sdr_payStt == '1' || reqInfo.sdr_payStt == '3'">
											<div class="btn_warp">
												<div class="reset-btn">已成交</div>
											</div>
										</template>
										<template v-else>
											<template v-if="reqInfo.isSelf != '1'">
												<div class="flex-end"
													v-if="reqInfo.sdr_reqType == '4' || reqInfo.sdr_reqType == '2' || (reqInfo.sdr_reqType == '1' && reqInfo.sdr_carry_model == '1') || reqInfo.sdr_reqType == '10'">
													<el-popover placement="right" trigger="manual" v-model="wxPayQRcode">
														<div class="wxqrcode_warp">
															<div class="icon_colse" @click="colseWxQrcode()"></div>
															<div class="wxqrcode_warp_left" v-loading="wxQrcoswloading">
																<div id="wxqrcode" :style="{width:'200px',height:'200px'}">
																</div>
																<div class="wxqrcode_bottom">
																	<div class="sys_icon"></div>
																	<div class="sys-desc">
																		<div>请使用微信扫一扫</div>
																		<div>扫描二维码支付</div>
																	</div>
																</div>
															</div>
															<div class="wxqrcode_warp_right"></div>
														</div>
														<div class="rest_btn" slot="reference" @click="showWXQRcode()">
															<div class="icon_weixin"></div>
															<div>微信支付</div>
														</div>
													</el-popover>
													<el-popover placement="right" width="400" trigger="manual"
														v-model="zfbPayQRcode">
														<div class="wxqrcode_warp">
															<div class="icon_colse" @click="colseAlipayQrcode()"></div>
															<div class="wxqrcode_warp_left" v-loading="alipayQrcoswloading">
																<div id="alipayqrcode" :style="{width:'200px',height:'200px'}">
																</div>
																<div class="wxqrcode_bottom">
																	<div class="sys_icon"></div>
																	<div class="sys-desc">
																		<div>请使用支付宝扫一扫</div>
																		<div>扫描二维码支付</div>
																	</div>
																</div>
															</div>
															<div class="zfbqrcode_warp_right"></div>
														</div>
														<div class="rest_btn" slot="reference" @click="showZFBQRcode()">
															<div class="icon_zfb"></div>
															<div>支付宝支付</div>
														</div>
													</el-popover>
												</div>
							
											</template>
											<div class="btn_warp" v-if="reqInfo.isSelf == '1'">
												<el-popover placement="left" width="200" trigger="click"
													popper-class="manager_btn_popper">
													<div class="pop_btn_warp">
														<div :class="[isStickTop?'active':'item_btn']" v-if="isStickTop">已置顶
														</div>
														<div class="item_btn" v-else @click="stickTopReq()">置顶</div>
														<div class="item_btn" @click="modifyReq()">编辑</div>
														<div class="item_btn" @click="putDownReq()">删除</div>
													</div>
													<div class="nomal_btn" slot="reference">管理</div>
												</el-popover>
											</div>
										</template>
									</div>
								</div>
							
						</div>
					
							<div style="width: 50%;padding-top: 20px;">
								<div class="flex-between person_warp">
									<div @click="showPerCenter">
										<personInfo :personId="reqInfo.sdr_userId"></personInfo>
									</div>
									<div class="btn_warp" v-if="reqInfo.isSelf == '0'">
										<div class="btn" @click="haveTalk()">聊一聊</div>
									</div>
								</div>
								
								<div class="con_area" v-if="isShowConcact">
									<pickContact ref="pickContact" :queryUid="reqInfo.sdr_userId" :baseUrlpath="baseUrlpath"
										:isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
								</div>
								<div class="con_area"
									v-if="reqInfo.sdr_reqType == '4' || reqInfo.sdr_reqType == '2' || (reqInfo.sdr_reqType == '1' && reqInfo.sdr_carry_model == '1') || reqInfo.sdr_reqType == '10'">
									<div class="conarea_head">
										<div class="conarea_head_tit">
											支付注意事项
										</div>
									</div>
									<div class="req_tips"
										v-if="reqInfo.sdr_reqType == '1' && reqInfo.sdr_carry_model == '1'">
										<div>1.支付前请确认与带货人确认好细节，尽量拍照给对方，在平台聊天工具中留下证据</div>
										<div>2.请不要在封闭的空间见面，尽量在空旷人多的安全地点约见</div>
										<div>3.请打开包装检查货物确认物品非违禁品，如因任何原因海关查扣或其他损失，本平台概不负责，需由双方自行协商解决</div>
										<div>4.请尽量选择已担保客户已实名认证的客户</div>
										<div>5.本平台仅提供信息交流服务，不承担任何法律责任</div>
										<div>6.平台支付后请勿立即确认完成，确认带货服务完成后，点击确认完成即可，确认完成后平台不可退款</div>
										<div>7.线上支付交易，平台收取6%服务费</div>
									</div>
									<div class="req_tips" v-if="reqInfo.sdr_reqType == '2'">
										<div>1.支付前请确认与发布人确认好细节</div>
										<div>2.请尽量选择已担保客户已实名认证的客户</div>
										<div>3.本平台仅提供信息交流服务，不承担任何法律责任</div>
										<div>4.平台支付后请勿立即确认完成，订单服务完成后点击确认完成即可，确认完成后平台不可退款</div>
										<div>5.线上支付交易，平台收取6%服务费</div>
									</div>
									<div class="req_tips" v-if="reqInfo.sdr_reqType == '4'">
										<div>1.支付前请确认与发布人确认好细节</div>
										<div>2.本平台不担保产品质量问题，闲置商品请双方仔细查验再购买</div>
										<div>3.双方送取货物就选择空旷人多的场所</div>
										<div>4.请尽量选择已担保客户已实名认证的客户</div>
										<div>5.本平台仅提供信息交流服务，不承担任何法律责任</div>
										<div>6.平台支付后请勿立即确认完成，收到货后，点击确认完成即可，确认完成后平台不可退款</div>
										<div>7.线上支付交易，平台收取6%服务费</div>
									</div>
									<div class="req_tips" v-if="reqInfo.sdr_reqType == '10'">
										<div>1.支付前请确认与发布人确认好细节</div>
										<div>2.请尽量选择已担保客户已实名认证的客户以防止后期质量问题</div>
										<div>3.本平台仅提供信息交流服务，不承担任何法律责任</div>
										<div>4.如未使用服务且不确定的情况下请勿点击确认完成</div>
										<div>5.平台支付后请勿立即确认完成，收到货或服务完成后，确认完成后平台不可退款</div>
										<div>6.线上支付交易，平台收取6%服务费</div>
									</div>
								</div>
								<div class="b-warp">
									<div class="bw-r">
										<div style="margin-bottom: 16px;">
											<div class="flex-start lev-msg-tab-warp">
												<div class="tab-item" :class="{'active':tabindex == '1'}"
													@click="tabindex = '1'">留言
												</div>
												<div class="tab-item" :class="{'active':tabindex == '2'}"
													@click="tabindex = '2'">出价
												</div>
											</div>
										</div>
										<div v-if="tabindex =='1'">
											<pinglun :reqNo="reqNo" :baseUrlpath="baseUrlpath"></pinglun>
										</div>
										<div v-if="tabindex == '2'">
											<chujia :reqNo="reqNo" :baseUrlpath="baseUrlpath"></chujia>
										</div>
									</div>
								</div>
							</div>
					</div>
					

					</div>
				</div>
			</div>
		</div>
		<div class="pers-drawer">
			<el-drawer title="" size="40%" :visible="percenterdrawer" :with-header="false" @close="handledrawerClose"
				direction="rtl">
				<customCenter ref="pct" :personId="reqInfo.sdr_userId"></customCenter>
			</el-drawer>
		</div>
		<el-dialog :visible.sync="preViewDialogVisible" title="图片预览">
		    <el-carousel height="520px" :autoplay="false">
		    	<el-carousel-item v-for="imageInfo in reqInfo.attachlist"
		    		:key="imageInfo.sdra_fileid">
		    		<div class="preview_img_warp" >
		    			<img :src="baseUrlpath+imageInfo.sdra_filePath" @error="imgOnError" class="preview_img"/>
		    		</div>
		    	</el-carousel-item>
		    </el-carousel>
		</el-dialog>
	</div>

</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo,
		postWithUInfo
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	import pinglun from '@/components/pinglun.vue'
	import chujia from '@/components/chujia.vue'
	import pickContact from '@/components/pickContact.vue'
	import customCenter from '@/components/customCenter.vue'
	import personInfo from '@/components/personInfo.vue'
	import QRCode from 'qrcodejs2'
	import detailSwiper from '@/components/swiper/detailSwiper.vue'
	export default {
		components: {
			pinglun,
			chujia,
			pickContact,
			customCenter,
			personInfo,
			detailSwiper
		},
		data() {
			return {
				baseUrlpath: '',
				initparams: {},
				reqInfo: {},
				activeName: 'first',
				comments: [],
				offers: [],
				reqNo: '',
				tabindex: '1',
				qrcodeWx: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				wxPayQRcode: false,
				zfbPayQRcode: false,
				defalutImg: require('@/assets/icon/noimg.jpg'),
				ispay: false,
				wxqrcode: null,
				checkInterval: null,
				wxQrcoswloading: true,
				alipayQrcoswloading: true,
				isStickTop: false,
				isShowConcact: false,
				searchLoading: false,
				percenterdrawer: false,
				isDetail: true,
				inchina:'',
				currentImageUrl: '', // 当前显示的图片链接
				preViewDialogVisible: false // 控制预览对话框的显示
			}
		},
		created() {
			// console.log(this.$route.query);
			this.initparams = this.$route.query;
			this.baseUrlpath = this.baseUrl;
			this.inchina = localStorage.getItem('inChina');
			if(this.inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
			this.reqNo = this.initparams.reqNo;
			this.getReqDetailInfo();
		},
		mounted() {
			window.scrollTo(0, 0)
			
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods: {
			handlePreview(file){
				this.currentImageUrl = file;
				this.preViewDialogVisible = true;
			},
			showPerCenter() {
				this.percenterdrawer = true
			},
			handledrawerClose() {
				this.percenterdrawer = false
			},
			play(fileId) {
				const videoElements = document.getElementsByTagName('video');
				console.log('页面的video数量====>' + videoElements.length)
				if (videoElements && videoElements.length > 0) {
					for (let i = 0; i < videoElements.length; i++) {
						if (fileId === videoElements[i].id) {
							videoElements[i].play()
						} else {
							videoElements[i].pause()
						}
					}
				}
			},
			showWXQRcode() {
				this.$util.checkLogin();
				this.$confirm('是否确定下单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.dealShowWXQRcode();
				})
			},
			async dealShowWXQRcode() {
				this.wxPayQRcode = true;
				var params = {};
				params.reqNo = this.initparams.reqNo;
				params.opTime = this.reqInfo.sdr_opTime;
				const initres = await queryUrlWithUInfo("/apis/createAdvanceOrder", params);
				if (initres.ec == '0' || initres.ec == 'EC999') {
					this.createWxNative(initres.aOrderNo)
				} else {
					this.$util.warnMsg(initres.msg);
				}
			},
			async createWxNative(aOrderNo) {
				var params = {};
				params.aOrderNo = aOrderNo;
				const initres = await postWithUInfo("/apis/createNative", params);
				if (initres.ec == '0') {
					this.createWxqrcode(initres.code_url, initres.outTradeNo);
				}
			},
			createWxqrcode(code, outTradeNo) {
				document.getElementById("wxqrcode").innerHTML = "";
				this.wxqrcode = new QRCode('wxqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.wxQrcoswloading = false
				this.checkInterval = setInterval(function() {
					vm.checkPayOrNot(outTradeNo)
				}, 2000);
			},
			async checkPayOrNot(outTradeNo) {
				const initres = await postWithUInfo("/apis/checkPayOrNot", {
					outTradeNo: outTradeNo
				});
				if (initres.ec == '0') {
					this.wxPayQRcode = false
					this.zfbPayQRcode = false
					this.$util.msg('支付完成')
					clearInterval(this.checkInterval)
					this.getReqDetailInfo();
				}
			},
			showZFBQRcode() {
				this.$util.checkLogin();
				this.$confirm('是否确定下单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.dealShowZFBQRcode();
				})
			},
			async dealShowZFBQRcode() {
				this.zfbPayQRcode = true
				var params = {};
				params.reqNo = this.initparams.reqNo;
				params.opTime = this.reqInfo.sdr_opTime;
				const initres = await queryUrlWithUInfo("/apis/createAdvanceOrder", params);
				if (initres.ec == '0' || initres.ec == 'EC999') {
					this.createZfbNative(initres.aOrderNo)
				} else {
					this.$util.warnMsg(initres.msg);
				}
			},
			async createZfbNative(aOrderNo) {
				var params = {};
				params.aOrderNo = aOrderNo;
				const initres = await postWithUInfo("/apis/alipay/transactionsH5", params);
				if (initres.ec == '0') {
					this.createAlipayqrcode(initres.code_url, initres.outTradeNo)
					/* var form= initres.payBody;
					const div = document.createElement('div')
					div.innerHTML = form//此处form就是后台返回接收到的数据
					document.body.appendChild(div)
					document.forms[0].submit() */
				}
			},
			createAlipayqrcode(code, outTradeNo) {
				var vm = this;
				document.getElementById("alipayqrcode").innerHTML = "";
				this.alipayqrcode = new QRCode('alipayqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.alipayQrcoswloading = false
				this.checkInterval = setInterval(function() {
					vm.checkPayOrNot(outTradeNo)
				}, 2000);
			},
			haveTalk() {
				var accesstoken = localStorage.getItem('accesstoken');
				if (!accesstoken) {
					this.$util.warnMsg('请先登录系统');
					return false
				}
				//https://www.fayanbo.com
				//http://localhost:8081
				var url = 'https://www.fayanbo.com/chat/#/message?token=' + accesstoken + '&fayanboId=' + this.userInfo
					.fayanboId + '&tId=' + this.reqInfo.sdr_userId + '&reqNo=' + this.initparams.reqNo;
				this.openWindow(url, '我的消息', 1000, 1000)
			},
			openWindow(url, title, w, h) {
				const dualScreenLeft =
					window.screenLeft !== undefined ? window.screenLeft : screen.left;
				const dualScreenTop =
					window.screenTop !== undefined ? window.screenTop : screen.top;

				const width = window.innerWidth ?
					window.innerWidth :
					document.documentElement.clientWidth ?
					document.documentElement.clientWidth :
					screen.width;
				const height = window.innerHeight ?
					window.innerHeight :
					document.documentElement.clientHeight ?
					document.documentElement.clientHeight :
					screen.height;

				const left = width / 2 - w / 2 + dualScreenLeft;
				const top = height / 2 - h / 2 + dualScreenTop;
				const newWindow = window.open(
					url,
					title,
					"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
					//"toolbar=yes, location=yes, directories=yes, status=yes, menubar=yes, scrollbars=yes, resizable=yes, copyhistory=yes, width=" +
					w +
					", height=" +
					h +
					", top=" +
					top +
					", left=" +
					left
				);

				if (window.focus) {
					newWindow.focus();
				}
				newWindow.location.reload(true)
			},
			getReqDetailInfo() {
				var vm = this;
				this.searchLoading = true
				var params = {};
				params.reqNo = vm.initparams.reqNo;
				var requestUrl = '/apis/getReqInfo'
				if(this.inchina == '1'){
					requestUrl = '/cnapis/getReqInfo'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.reqInfo = res.reqInfo
					vm.isStickTop = res.reqInfo.isStickTop
					vm.isShowConcact = res.reqInfo.sdr_isShowConcact == '1' ? true : false
					vm.searchLoading = false
				});

				var getPriceByReqNoUrl = "/apis/getPriceByReqNo"
				if(this.inchina == '1'){
					getPriceByReqNoUrl = "/cnapis/getPriceByReqNo"
				}
				queryUrl(getPriceByReqNoUrl, {
					reqNo: vm.initparams.reqNo
				}).then(res => {
					vm.offers = res.reList
				});
			},
			goBack() {
				this.$util.goBack();
				//this.$router.go(-1)
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			colseWxQrcode() {
				this.wxPayQRcode = false
			},
			colseAlipayQrcode() {
				this.zfbPayQRcode = false
			},
			putDownReq() {
				var _this = this;
				this.$confirm('确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.soldOut();
				}).catch(() => {

				});
			},
			async soldOut() {
				var _this = this;
				const initres = await queryUrl('/apis/soldOutReq', {
					reqNo: this.reqNo
				});
				if (initres.ec == '0') {
					this.$util.msg("删除成功", {}, () => {
						_this.$util.goBack();
					});
				} else {
					this.$util.msg(initres.msg)
				}
			},
			async stickTopReq() {
				if (this.isStickTop) {
					return;
				}
				var needCost = 0;
				var _this = this;
				const initres = await queryUrl('/apis/queryJdNeedCost', {
					queryType: '10'
				});
				if (initres.ec == '0') {
					needCost = initres.jdCost;
				}
				this.$confirm('置顶需花费' + needCost + '金豆', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.dealStickTopReq();
				}).catch(() => {

				});
			},
			async dealStickTopReq() {
				const initres = await queryUrl('/apis/dealReqStickTop', {
					reqNo: this.reqNo
				})
				if (initres.ec == '0') {
					this.isStickTop = true;
					this.$util.msg('置顶成功');
				} else {
					var msg = initres.msg;
					this.$util.msg(msg);
				}
			},
			modifyReq() {
				console.log(this.reqInfo.sdr_reqType)
				var url = '';
				if (this.reqInfo.sdr_reqType == '1') { //航班带货
					url = '/modifycommerce';
				} else if (this.reqInfo.sdr_reqType == '2') { //顺风车
					url = '/modifysfc'
				} else if (this.reqInfo.sdr_reqType == '4') { //甩卖
					url = '/modifysale'
				} else if (this.reqInfo.sdr_reqType == '3') { //招聘
					url = '/modifyrecruit'
				} else if (this.reqInfo.sdr_reqType == '5') { //活动
					url = '/modifyactivity'
				} else if (this.reqInfo.sdr_reqType == '9') { //车
					url = '/modifylocomotive'
				} else if (this.reqInfo.sdr_reqType == '10') { //旅游
					url = '/modifytravel'
				} else if (this.reqInfo.sdr_reqType == '11') { //房
					url = '/modifyhouse'
				} else if (this.reqInfo.sdr_reqType == '12') { //美食资讯
					url = '/modifyDelicacy'
				} else if (this.reqInfo.sdr_reqType == '13') { //华人互动  国货出海
					url = '/modifyCnyGoodExpt'
				} else if (this.reqInfo.sdr_reqType == '14') { //商业机会
					url = '/modifyRoast'
				} else if (this.reqInfo.sdr_reqType == '15') { //通讯录
					url = '/modifyTel'
				} else if (this.reqInfo.sdr_reqType == '16') { //汇率
					url = '/modifyRate'
				} else if (this.reqInfo.sdr_reqType == '7') { //本地折扣
					url = '/modifyDiscounts'
				}
				this.$router.push({
					name: url,
					query: {
						reqNo: this.reqNo
					}
				});
			}
		}

	}
</script>

<style scoped="scoped" lang="scss">
	.preview_img_warp {
		width: 100%;
		height: 100%;
		.preview_img {
			object-fit: contain !important;
			width: 100%;
			height: 100%;
		}
	}

	.conarea_head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 15px;
		position: relative;
		padding: 0 10px;
		margin-top: 16px;
		margin-bottom: 8px;

		.conarea_head_tit {
			font-weight: 700;
			font-size: 18px;
		}

		.conarea_head_tit::after {
			content: "";
			position: absolute;
			background-color: #c88854;
			height: 20px;
			width: 4px;
			top: 50%;
			left: 0;
			border-radius: 8px;
			transform: translate(0, -50%);
		}
	}

	.date_ymd {
		width: 110px;
	}

	.person_warp {
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 14px 20px;
		.btn_warp {
			margin-left: 50px;
		}
	}

	.per_info_warp {
		cursor: pointer;
	}

	::v-deep .el-statistic {
		background-color: #d9d9d9;
		padding: 5px;
		box-sizing: border-box;
		margin-right: 10px;
		border-radius: 7px;
	}

	::v-deep .el-statistic .con .number {
		font-size: 18px !important;
		padding: 0 4px;
		width: 130px;
		line-height: 30px;
	}
	
	::v-deep .el-statistic .head {
		font-size: 16px !important;
	}

	.con_area {
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		padding: 14px 20px;
		margin-top: 14px;
		word-break: break-all;
		text-align: left;
	}

	.wxqrcode_warp {
		display: flex;
		justify-content: space-around;
		align-items: center;
		//padding: 20px 0px 20px 20px;
		position: relative;

		.icon_colse {
			width: 26px;
			height: 26px;
			background: url(../../assets/icon/close.png) center no-repeat;
			background-size: 100%;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}

		.wxqrcode_warp_left {
			display: flex;
			flex-direction: column;

			.wxqrcode_bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;

				.sys-desc {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: column;
					font-size: 14px;
					color: #1d1b1b;
				}

				.sys_icon {
					background: url(../../assets/icon/saoyisao.png) center no-repeat;
					background-size: 100%;
					width: 30px;
					height: 30px;
					margin-right: 20px;
				}
			}
		}

		.wxqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/pc_icon_phone-bg.png) center no-repeat;
			background-size: 80%;
		}

		.zfbqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/zfbScan.png) center no-repeat;
			background-size: 90%;
		}
	}

	.lev-msg-tab-warp {
		/* border-bottom: 1px solid #E4E7ED;
		border-radius: 4px 4px 0 0; */
		box-sizing: border-box;
	}

	.lev-msg-tab-warp .tab-item {
		height: 40px;
		box-sizing: border-box;
		line-height: 40px;
		display: inline-block;
		list-style: none;
		font-size: 16px;
		font-weight: 800;
		color: rgb(43, 45, 46);
		text-align: left;
		position: relative;
		border-bottom: none;
		width: 82px;
		cursor: pointer;
	}

	.lev-msg-tab-warp .tab-item:not(:first-child) {
		border-left: none;
	}

	.lev-msg-tab-warp .active {}

	.lev-msg-tab-warp .active::after {
		content: "";
		display: block;
		width: 32px;
		height: 4px;
		background-color: #C88854;
		position: absolute;
		bottom: 0px;
		border-radius: 2px;
	}

	.container {
		width: 90%;
		margin: 0 auto;
		height: 100%;
	}

	.conts {
		min-height: 900px;
		margin-top: 20px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);

		.pad_cons {}
	}

	.cons_left {
		padding: 0px 10px 0px 10px;

		.carousel-warp {
			width: 100%;
			video {
				width: 100%;
				height: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.imagewarp {
				width: 100%;
				height: 100%;
			}
		}
	}

	.tb-wrap {
		flex: 1;
		padding: 20px 20px 20px 10px;
		.ri_foot {
			margin-top: 40px;
			float: right;
		}
	}

	.btn_warp {
		/* width: 100px; */

		.pop_btn_warp {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.reset-btn {
			background-color: #8C939D;
			color: #FFFFFF;
			padding: 7px 20px;
			border-radius: 20px;
			font-size: 12px;
			cursor: pointer;
		}

		.nomal_btn {
			background-color: #FFFFFF;
			color: #C88854;
			padding: 6px 24px;
			border-radius: 20px;
			font-size: 14px;
			cursor: pointer;
			border: 1px solid;
		}

		.btn {
			background-color: #C88854;
			color: #FFFFFF;
			padding: 7px 20px;
			border-radius: 20px;
			font-size: 12px;
			cursor: pointer;
		}



		.rest_btn:hover {
			color: #c88854;
		}
	}

	.rest_btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		cursor: pointer;

		.icon_weixin {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/PAY-WEIXIN.png) no-repeat center;
			background-size: 100%;
			margin-right: 4px;
		}

		.icon_zfb {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/zfb.png) no-repeat center;
			background-size: 100%;
			margin-right: 4px;
		}
	}

	.tb-wrap .ri-body {
		font-size: 18px;
	}

	.tb-wrap .ri-body .rib-nail {
		padding: 10px 0 0 0;
		text-align: left;
		word-break: break-all;
	}

	.tb-wrap .ri-body .icon-direct {
		background: url(../../assets/icon/to-right.png) center no-repeat;
		background-size: 40%;
		width: 70px;
		height: 20px;
	}

	.tb-wrap .ri-body .carry_from_place,
	.carry_to_place {
		font-weight: bold;
	}

	.tb-wrap .ri-body .mrgr {
		margin-right: 4px;
	}

	.tb-wrap .ri-body .rib-nail .lb {
		text-align: left;
		font-weight: 400;
		font-size: 18px;
		color: #303133;
	}

	.tb-wrap .ri-body .rib-head {
		color: #c88854;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-weight: 700;
		font-size: 18px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 14px 20px;
		line-height: 70px;
	}

	.tb-wrap .ri-body .req-tag {
		border: 1px solid #bfbfbf;
		color: #777474;
		padding: 4px 12px;
		border-radius: 6px;
		font-size: 14px;
		margin-right: 8px;
		letter-spacing: 1px;
	}

	.b-warp {
		display: flex;
		justify-content: flex-start;
		margin-top: 20px;
	}

	.b-warp .bw-r {
		width: 100%;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 14px 20px;
	}

	.usr {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.sm,
		.db {
			display: flex;
			background-color: #8C939D;
			color: #FFFFFF;
			align-items: center;
			justify-content: center;
			font-size: 10px;
			padding: 4px 6px;
			border-radius: 6px;

			.icon-sm {
				background: url(../../assets/icon/sm.png) center no-repeat;
				background-size: 100%;
				height: 14px;
				width: 14px;
			}

			.icon-db {
				background: url(../../assets/icon/db.png) center no-repeat;
				background-size: 100%;
				height: 14px;
				width: 14px;
			}
		}

		.active {
			background-color: #c88854 !important;
		}

		.nick {
			font-size: 14px;
			font-weight: 600;
			color: #5e5a5a;

			.man {
				background: url(../../assets/icon/man.png) center no-repeat;
				background-size: 100%;
				height: 16px;
				width: 16px;
			}

			.woman {
				background: url(../../assets/icon/woman.png) center no-repeat;
				background-size: 100%;
				height: 16px;
				width: 16px;
			}
		}

		.avatar {
			width: 60px;
			height: 60px;
			border-radius: 40px;
			overflow: hidden;
			margin-right: 12px;
		}

		.avatar img {
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}

	.nail-price {
		color: #FF0000;
		font-size: 18px;
		font-weight: bold;
	}

	.nail-price .currency_type {
		margin-right: 6px;
		font-size: 16px;
	}

	.amt-unit {
		font-size: 13px;
		display: flex;
		justify-content: flex-start;
		align-items: baseline;
	}

	.amt-unit .unit-split {
		margin: 0 4px;
	}

	.op {
		font-size: 10px;
		color: #a09696;
		text-decoration: line-through;
		margin-left: 10px;
	}
</style>
